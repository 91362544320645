/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/types/cros-bicros/'], ['en', 'https://www.hear.com/hearing-aids/types/cros-bicros/'], ['en-US', 'https://www.hear.com/hearing-aids/types/cros-bicros/'], ['en-CA', 'https://ca.hear.com/hearing-aids/types/cros-bicros/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "cutting-edge-technology-for-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cutting-edge-technology-for-your-ears",
    "aria-label": "cutting edge technology for your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cutting-edge technology for your ears"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With all of today’s advancements in technology, it’s easy to find a hearing solution tailored to your individual needs. For example, if you have a form of unilateral hearing loss (single-sided deafness), new CROS (“Contralateral Routing of Signal”) or BiCROS hearing aids will not only improve your ability to hear, but they can help with directional hearing, allowing you to localize sounds and pinpoint exactly where they’re coming from."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Continue reading to learn more about directional hearing and to find out how CROS and BiCROS hearing aids can help with unilateral hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-importance-of-directional-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-importance-of-directional-hearing",
    "aria-label": "the importance of directional hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The importance of directional hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When listening with two healthy ears (binaural hearing), sound reaches both of them at different volumes and frequencies. This allows the brain to localize the direction of the sounds for orientation purposes. Therefore, an individual with binaural hearing will be able to easily locate the source of any given noise. This is called directional hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In contrast, those with unilateral hearing loss will only hear on the side of their healthy ear. Because of this, they lose the ability to orientate the location of sounds. Unfortunately, turning one’s head does not help in these types of situations. Additionally, those with unilateral hearing loss also find it difficult to concentrate on a specific sound – like speech – when in noisy environments, making it a challenge to carry conversations in public places."), "\n", React.createElement(ButtonCta, {
    copy: "TRY HEARING AIDS NOW",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-cros-hearing-aids-help-with-unilateral-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-cros-hearing-aids-help-with-unilateral-hearing-loss",
    "aria-label": "how cros hearing aids help with unilateral hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How CROS hearing aids help with unilateral hearing loss"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/cros.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "In conventional hearing aids, the microphone and receiver are close together, amplifying the signal at the respective ear. However, to help those with single-sided deafness, engineers have developed the CROS hearing aid, where the receiver and microphone are decoupled and attached at different locations."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "With CROS hearing aids, a microphone records sound from the individual’s deaf side and transfers it directly into a receiver in the healthy ear. This does not influence the healthy ear’s normal capacity. Rather, it permits sounds arriving at the impaired ear to be recorded, processed by the brain, and ultimately heard. In short, a variation of directional hearing is made possible once again.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cros-hearing-aids-wireless-and-discreet",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cros-hearing-aids-wireless-and-discreet",
    "aria-label": "cros hearing aids wireless and discreet permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CROS hearing aids: wireless and discreet"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aid/brands",
    className: "c-md-a"
  }, "Hearing aid manufacturers"), ", have developed ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "cutting-edge technology"), " that transmits sound wirelessly from one hearing aid to the other via radio signals.\nTo allow sound to reach the unimpaired ear accurately and quickly, an audiologist will need to make a cast of your ear. This ensures that the fitted audio piece (the otoplasty) is able to fit comfortably into your ear canal and have the best precision possible. Depending on the manufacturer of your CROS hearing aid, ", React.createElement(_components.a, {
    href: "/hearing-aids/accessories",
    className: "c-md-a"
  }, "the technical accessories"), " for the devices may vary slightly. CROS hearing aids are available in ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte",
    className: "c-md-a"
  }, "behind-the-ear (BTE"), " and ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "in-the-ear (ITE)"), " versions, which are connected to the otoplasty. In the BTE version, the microphone is located behind the impaired ear, while the receiver is located behind the healthy ear. All of these components are discreet, comfortable, and improve hearing tremendously."), "\n", React.createElement(ButtonCta, {
    copy: "TRY CROS HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-magic-of-bicros-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-magic-of-bicros-hearing-aids",
    "aria-label": "the magic of bicros hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The magic of BiCROS hearing aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/bi-cros.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "BiCROS hearing aids (bilateral microphones with contralateral routing of signal) are designed for people who have bilateral hearing loss"), ", or hearing loss in both ears. A BiCROS system consists of a microphone that picks up sounds from the ear with little to no hearing and a receiver in the ear with better hearing. The ear with better hearing processes the sounds sent there by the microphone. A traditional hearing aid in the ear with normal hearing amplifies the sounds that are sent there from the side with no hearing.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cros--bicros-hearing-aids-available-with-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cros--bicros-hearing-aids-available-with-hearcom",
    "aria-label": "cros  bicros hearing aids available with hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CROS & BiCROS hearing aids available with hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Do you have unilateral hearing loss? Find your perfect CROS or BiCROS hearing aid today with hear.com! We carry all the latest CROS or BiCROS devices from the leading manufacturers. Simply give us a call or fill out the questionnaire on this page to try CROS or BiCROS hearing aids, risk-free, for 30 days."), "\n", React.createElement(ButtonCta, {
    copy: "TRY HEARING AIDS NOW",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
